<!--  -->
<template>
  <div class="">
    <h1>请选择要咨询的xxx记录</h1>
    <div>
      <el-form ref="form" :model="form">
        <el-col :span="2"><div class="blank"></div></el-col>
        <el-form-item>
          <span slot="label">
            <span class="form-name">选择起始日期 </span>
          </span>
          <el-col :span="11" class="blank">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="form.date1"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-col :span="2"><div class="blank"></div></el-col>
        <el-form-item>
          <span slot="label">
            <span class="form-name">选择截止日期 </span>
          </span>
          <el-col :span="11" class="blank">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="form.date2"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-col :span="2"><div class="blank"></div></el-col>
        <el-form-item>
          <el-button class="button" type="primary">确认查询</el-button>
          <!-- @click -->
          <el-button type="primary" plain>查询所有记录</el-button>
          <!-- @click -->
        </el-form-item>
      </el-form>

      <!-- 添加搜索\刷新功能 -->
      <div>
        <el-col :span="21"><div class="blank"></div></el-col>

        <el-button circle><i class="el-icon-search"></i></el-button>
        <el-button circle><i class="el-icon-refresh"></i></el-button>
        <el-button circle><i class="el-icon-s-grid"></i></el-button>
      </div>
      <!-- 表格内容 -->
      <el-table
        :data="tableData"
        height="300"
        :header-cell-style="tableHeaderColor"
        border
        stripe
        style="width: 100%"
      >
        <el-table-column
          fixed
          prop="id"
          label="编号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="date"
          label="记录时间"
          width="320"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="read"
          label="选择咨询"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="read1"
          label="咨询结果"
          width="350"
          align="center"
        >
        </el-table-column>
        <el-table-column label="删除" align="center"> </el-table-column>
        <!-- <el-button           
          size="mini"
          type="danger"
          >删除</el-button>    -->
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      tableData: [
        {
          id: 1,
          date: "2016-05-02",
        },
        {
          id: 2,
          date: "2016-05-04",
        },
        {
          id: 3,
          date: "2016-05-01",
        },
        {
          id: 4,
          date: "2016-05-03",
        },
      ],
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableData.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //设置表头行的样式
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return "color:#000 ;font-size:18px;text-align:center";
    },
  },
};
</script>
<style >
.blank {
  border-radius: 4px;
  height: 20px;
}
.form-name {
  display: inline-block;
  font-weight: bold;
  font-size: 20px;
}
</style>